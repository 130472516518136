import React, { useState } from "react";

const defaultState = {
  token: null,
  modalOpen: false,
  setModalOpen: () => { },
  externalUrl: null,
  setExternalUrl: () => { },
  modalType: null,
  setModalType: () => { },
};

const AppContext = React.createContext(defaultState);

const AppProvider = ({ children }) => {
  const [token, setToken] = useState(defaultState.token);
  const [modalOpen, setModalOpen] = useState(defaultState.modalOpen);
  const [externalUrl, setExternalUrl] = useState(defaultState.externalUrl);
  const [modalType, setModalType] = useState(defaultState.modalType);

  const store = {
    token: token,
    setToken: setToken,
    modalOpen: modalOpen,
    setModalOpen: setModalOpen,
    externalUrl: externalUrl,
    setExternalUrl: setExternalUrl,
    modalType: modalType,
    setModalType: setModalType,
  };
  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
